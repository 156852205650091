import React from "react";
import { Route, Routes } from "react-router-dom";
import { Admin } from "../pages/admin.page";
import { Designer } from "../pages/designer.page";
import { Home } from "../pages/home.page";
import MyAppPage from "../pages/my-app.page";
import { AllApp } from "../pages/all-app.page";
import style from "./index.module.less";
import LoginPage from "../pages/login.page";
import loginStore from "../store/login.store";
import myAppsStore from "../store/my-apps.store";
import { PageNotFound } from "../components/route/page-not-found";
import AppWorkbench from "../components/my-app-page/AppWorkbench";

export function MainContent() {
  return (
    <div className={style["layout-content"]}>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route index element={<Home />}></Route>
        <Route path="/home" element={<Home />} />
        <Route path="/apps">
          <Route index element={<AllApp />} />
          <Route path="all" element={<AllApp />} />
          <Route
            path="mine"
            element={
              <MyAppPage myAppStore={myAppsStore} loginStore={loginStore} />
            }
          >
            <Route
              path=":appId/*"
              element={<AppWorkbench myAppStore={myAppsStore} />}
            />
          </Route>
        </Route>
        <Route path="/designer" element={<Designer />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/login" element={<LoginPage loginStore={loginStore} />} />
        <Route
          path="/sign-up"
          element={<LoginPage loginStore={loginStore} />}
        />
      </Routes>
    </div>
  );
}
