import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./index.module.less";

interface IProps {
  menus: {
    id: number;
    title: string;
    path: string;
  }[];
}

function AppWorkbenchMenu(props: IProps) {
  // active the first menu as default.
  const [activeId, setActiveId] = useState(1);
  const navigate = useNavigate();
  const { menus } = props;
  return (
    menus && (
      <div className={style.wrapper}>
        {menus.map((menu, idx) => {
          return (
            <div
              key={idx}
              className={activeId === menu.id ? style["active"] : ""}
              onClick={() => {
                setActiveId(menu.id);
                navigate(menu.path);
              }}
            >
              {menu.title}
            </div>
          );
        })}
      </div>
    )
  );
}

export default AppWorkbenchMenu;
