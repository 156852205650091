import React from "react";
import style from "./index.module.scss";

interface IProps {
  url: string;
  idx?: number;
}

export function HomeSwiperElement(props: IProps) {
  const url = props.url;
  return (
    <div className={style.container}>
      <img src={url} alt="图片" />
    </div>
  );
}
