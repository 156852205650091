import React from "react";
import { HomeSwiper } from "../components/home-page/HomeSwiper";
import style from "./home.page.module.less";

export function Home() {
  console.log(style);
  return (
    <div className={style.container}>
      <div className={style.left}>
        <HomeSwiper />
      </div>
      <div className={style.right}></div>
    </div>
  );
}
