import { observer } from "mobx-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../../../../../api/requst";
import { DeployStore } from "../../../../../../store/deploy.store";
import MsgBox from "../../../../../msg-popup-box";
import DeployQC from "../DeployQC";
import style from "./index.module.less";

interface IProps {
  deployStore: DeployStore;
  selectedRows: Array<any>;
}

function ActionArea(props: IProps) {
  const { showDeployQC, setShowDeployQC, forceRefresh } = props.deployStore;
  const { selectedRows } = props;
  const { appId } = useParams();
  const [msg, setMsg] = useState<{
    type?: number;
    content?: string;
    onsure?: Function;
    show: boolean;
  }>({ show: false });

  const onFileUploadClk = () => {
    setShowDeployQC(true);
  };

  // go live an app
  const onProd = () => {
    if (selectedRows.length === 0) {
      setMsg({ show: true, type: 2, content: "请至少选择一条发布记录" });
    } else if (selectedRows.length === 1) {
      setMsg({
        show: true,
        type: 3,
        content: "该操作将导致之前上线的版本失效。确认发布吗？",
        onsure: async () => {
          setMsg({ show: false });
          const selectedRowDBId = selectedRows[0].values.id;
          request({
            path: "deploy/active/" + appId + "/" + selectedRowDBId,
            method: "post",
            param: { env: 0 },
          })
            .then((res) => {
              if (res.data.code !== 200) {
                setMsg({ show: true, content: res.data.message });
              } else {
                setMsg({ show: true, content: "项目已部署生效" });
                forceRefresh();
              }
            })
            .catch((err) => {
              setMsg({ show: true, content: "上线失败，请联系管理员！" });
            });
        },
      });
    } else {
      setMsg({ show: true, type: 2, content: "只能上线一条发布记录" });
    }
  };

  return (
    <>
      {msg.show && (
        <MsgBox
          type={msg.type}
          content={msg.content}
          onClose={() => {
            setMsg({ ...msg, show: false });
          }}
          onSure={msg.onsure}
        />
      )}
      <div className={style.wrapper}>
        <button onClick={onFileUploadClk}>上传</button>
        <button onClick={onProd}>上线</button>
        <button>查找</button>
        <button>删除</button>
        <button>刷新</button>
        <div>
          {showDeployQC && <DeployQC deployStore={props.deployStore} />}
        </div>
      </div>
    </>
  );
}

export default observer(ActionArea);
