import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { MyAppStore } from "../../../store/my-apps.store";
import { IAppInfo } from "../../../types/my-app.type";
import AppWorkbenchHeader from "./Header";
import AppWorkbenchMenu from "./Menu";
import style from "./index.module.less";
import WorkSpace from "./MenuContent/WorkSpace";
import { AppSettings } from "./MenuContent/AppSettings";
import { AppDetail } from "./MenuContent/AppDetail";
import deployStore from "../../../store/deploy.store";
import { request } from "../../../api/requst";

interface IProps {
  myAppStore: MyAppStore;
}

function AppWorkbench(props: IProps) {
  const { appId } = useParams();
  const [appInfo, setAppInfo] = useState<IAppInfo>();

  if (!Number.isInteger(+appId)) {
    // todo: change to pop up.
    throw new Error("appId必须为正整数！");
  }

  const menus = [
    { id: 1, title: "工作台", path: "workspace" },
    { id: 2, title: "设置", path: "settings" },
    { id: 3, title: "详情", path: "info" },
  ];

  useEffect(() => {
    request({ path: "my-app/" + appId, method: "get" }).then((res) => {
      if (res.data.code === 200) {
        setAppInfo(res.data.data);
      }
    });
  }, [appId]);

  return (
    appInfo && (
      <div className={style["wrapper"]}>
        <div className={style["header-info"]}>
          <AppWorkbenchHeader appInfo={appInfo} />
        </div>
        <div className={style["main-content"]}>
          <div className={style["menu"]}>
            <AppWorkbenchMenu menus={menus} />
          </div>
          <div className={style["menu-content-wrapper"]}>
            <div className={style["content"]}>
              <Routes>
                <Route
                  index
                  element={<WorkSpace deployStore={deployStore} />}
                />
                <Route
                  path={menus[0].path}
                  element={<WorkSpace deployStore={deployStore} />}
                />
                <Route path={menus[1].path} element={<AppSettings />} />
                <Route path={menus[2].path} element={<AppDetail />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default observer(AppWorkbench);
