import axios from "axios";

axios.defaults.withCredentials = true;

/**
 * @description:
 * @param {string} params.path
 * @param {string} params.method: get,post,delete
 * @param {object} params.param
 * @param {object} params.headers
 * @return {object} Promise<response>
 */
const request = async (params: {
  path: string;
  method: "get" | "post" | "delete";
  param?: {} | FormData;
  headers?: {};
  auth?: { username: string; password: string };
}) => {
  const { path, method, param, headers, auth } = params;
  const env = process.env.REACT_APP_NODE_ENV;
  const host = env === "prod" ? "https://sumeet.cn" : "http://127.0.0.1:7001";
  const url = `${host}/api/${path}`;

  switch (method) {
    // get 请求
    case "get":
      return await axios.get(url, { params: param, headers });

    // post 请求
    case "post":
      return await axios.post(url, param, { headers, auth });

    // delete 请求
    case "delete":
      return await axios.delete(url, { params: param, headers });
    default:
      throw new Error("请传入正确的method");
  }
};

export { request };
