import React from "react";

interface IProps {
  children: React.ReactElement;
}

export class ErrorBoundary extends React.Component {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state["hasError"]) {
      return <h1>Something went wrong. Please refresh.</h1>;
    }

    return this.props["children"];
  }
}
