import React from "react";
import style from "./index.module.less";

interface IProps {
  type: number;
  content: string;
  onClose: Function;
  onSure?: Function;
}

function MsgBox(props: IProps) {
  const { onSure, content } = props;

  const getTypeStr = () => {
    const type = props.type;
    if (type === 1) {
      return "错误";
    } else if (type === 2) {
      return "警告";
    } else if (type === 3) {
      return "通知";
    } else {
      return "未知类型的消息";
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.header}>{getTypeStr()}</div>
        <div className={style.detail}>{content}</div>
        <div className={style.btns}>
          {onSure && <button onClick={() => onSure()}>确定</button>}
          <button onClick={() => props.onClose()}>关闭</button>
        </div>
      </div>
    </div>
  );
}

export default MsgBox;
