import React, { useState } from "react";
import { DeployStore } from "../../../../../store/deploy.store";
import ActionArea from "./ActionArea";
import style from "./index.module.less";
import TableArea from "./TableArea";
import { observer } from "mobx-react";

interface IProps {
  deployStore: DeployStore;
}

function WorkSpace(props: IProps) {
  const [selectedRows, setSelectedRows] = useState<Array<any>>();

  return (
    <div className={style.wrapper}>
      <ActionArea deployStore={props.deployStore} selectedRows={selectedRows} />
      <TableArea
        deployStore={props.deployStore}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
}

export default observer(WorkSpace);
