import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { MainContent } from "./layout/main-content";
import Header from "./layout/header";
import loginStore from "./store/login.store";
import { ErrorBoundary } from "./components/error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header loginStore={loginStore} />
      <ErrorBoundary>
        <MainContent />
        <div>
          <p
            style={{
              background: "lightgray",
              height: "5%",
              position: "fixed",
              bottom: "-2%",
              width: "100%",
              textAlign: "center",
              paddingTop: "2%",
              zIndex: 1000,
            }}
          >
            <img
              style={{
                paddingTop: "2px",
                width: "20px",
                height: "20px",
                verticalAlign: "bottom",
                marginRight: "5px",
              }}
              src="https://img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png"
            />
            <a
              className="banquan"
              style={{ color: "#000" }}
              href="https://beian.miit.gov.cn/"
              target="_blank"
            >
              黔ICP备2022002286号-1
            </a>
          </p>
        </div>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);
