import React, { useEffect } from "react";
import style from "./index.module.less";
import { useTable, usePagination, useRowSelect } from "react-table";
import { DeployStore } from "../../../../../../store/deploy.store";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

interface IProps {
  deployStore: DeployStore;
  setSelectedRows: Function;
}

function TableArea(props: IProps) {
  const { appId } = useParams();
  const { deployStore } = props;
  const {
    getDeployRecByPage,
    setTableOptions,
    setCurPage,
    refresh,
    deployRecords,
    curPage,
    showDeployQC,
  } = deployStore;

  const data = React.useMemo(() => {
    return deployRecords;
  }, [deployRecords]);

  const columns = React.useMemo(
    () => [
      // accessor is the "key" in the data
      { Header: "ID", accessor: "id" },
      { Header: "序号", accessor: "order" },
      { Header: "入口文件", accessor: "entry" },
      { Header: "发布信息", accessor: "comment" },
      { Header: "操作人", accessor: "processor" },
      { Header: "创建时间", accessor: "createdAt" },
      { Header: "修改时间", accessor: "modifiedAt" },
      { Header: "环境", accessor: "env" },
    ],
    []
  );

  const IndeterminateCheckbox = React.forwardRef<any, any>(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef["current"].indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return <input type="checkbox" ref={resolvedRef as any} {...rest} />;
    }
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: curPage - 1, hiddenColumns: ["id"] },
    } as any,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          // @ts-ignore
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row["getToggleRowSelectedProps"]()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = tableInstance as any;

  setTableOptions({
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  });

  const fetchData = async () => {
    await getDeployRecByPage({ page: 1, pageSize: 10, appId: +appId });
    await getDeployRecByPage({ page: 2, pageSize: 10, appId: +appId });
    await getDeployRecByPage({ page: 3, pageSize: 10, appId: +appId });
    await getDeployRecByPage({ page: 4, pageSize: 10, appId: +appId });
  };

  useEffect(() => {
    fetchData();
  }, [appId, showDeployQC, refresh]);

  useEffect(() => {
    props.setSelectedRows(new Array(...selectedFlatRows));
  });

  return (
    <div className={style.wrapper}>
      <div className={style.banner} />
      <div className={style["table-content"]}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={style[column.id]}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={style[cell.column.id]}
                      >
                        <span className={cell.value && style["content"]}>
                          {cell.render("Cell")}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={style.pagination}>
        <button
          onClick={() => {
            previousPage();
            setCurPage(curPage - 1);
          }}
          disabled={!canPreviousPage}
        >
          上一页
        </button>
        <span>
          页码 {" " + (pageIndex + 1)} / {pageCount}{" "}
        </span>
        <button
          onClick={() => {
            nextPage();
            setCurPage(curPage + 1);
          }}
          disabled={!canNextPage}
        >
          下一页
        </button>
      </div>
    </div>
  );
}

export default observer(TableArea);
