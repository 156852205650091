import React from "react";
import { IAppInfo } from "../../../../types/my-app.type";
import style from "./index.module.less";
import dayjs from "dayjs";
import { toPage } from "../../../../tools/toPage";

interface IProps {
  appInfo: IAppInfo;
}

function AppWorkbenchHeader(props: IProps) {
  const { enName, zhName, url, createdAt } = props.appInfo;

  return (
    <div className={style.wrapper}>
      <div className={style["base-info"]}>
        <div>中文名：{enName}</div>
        <div>英文名：{zhName}</div>
        <div>创建日期：{dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>
      </div>
      <div className={style.url} onClick={() => toPage(url)}>
        点击访问
      </div>
    </div>
  );
}

export default AppWorkbenchHeader;
