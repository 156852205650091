import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { request } from "../../../api/requst";
import { MyAppStore } from "../../../store/my-apps.store";
import { Mask } from "../../Mask";
import MsgBox from "../../msg-popup-box";
import style from "./index.module.less";

interface IProps {
  myAppStore: MyAppStore;
}

function AppQuickCreate(props: IProps) {
  const { setShowQC, pushAppsInfo } = props.myAppStore;

  const zhNameRef = useRef(null);
  const enNameRef = useRef(null);
  const urlRef = useRef(null);
  const coverRef = useRef(null);
  const repoRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [zhNameRuleMeet, setZhNameRuleMeet] = useState(true);
  const [enNameRuleMeet, setEnNameRuleMeet] = useState(true);
  const [urlRuleMeet, setUrlRuleMeet] = useState(true);
  const [coverRuleMeet, setCoverRuleMeet] = useState(true);
  const [repoRuleMeet, setRepoRuleMeet] = useState(true);
  const [msg, setMsg] = useState<{
    show: boolean;
    type: number;
    content: string;
  }>({ show: false, type: null, content: null });

  const onChange = () => {
    const zhName: string = zhNameRef.current.value;
    const enName: string = enNameRef.current.value;
    const url: string = urlRef.current.value; // app's URL.
    const cover: string = coverRef.current.value; // app's head image.
    const repo: string = repoRef.current.value; // reposiroty URL, e.g. github.
    urlRef.current.value = enName && "https://" + enName + ".sumeet.cn";
    setZhNameRuleMeet(/[0-9a-zA-Z\u4e00-\u9fa5]{3,40}/.test(zhName)); // alphabet, chinese and number are allowed.
    setEnNameRuleMeet(/^[a-zA-Z][-_a-zA-Z0-9]{3,40}/.test(enName)); // begin with alphabet, - and number are allowed.
    setUrlRuleMeet(url && /https?:\/\/.+/.test(url)); // begin with https://
    setCoverRuleMeet(!cover || /https?:\/\/.+/.test(cover));
    setRepoRuleMeet(!repo || /https?:\/\/.+/.test(repo));

    return { zhName, enName, url, cover, repo };
  };

  // confirm to create an app.
  const onSubmit = async () => {
    if (
      !(
        zhNameRuleMeet &&
        enNameRuleMeet &&
        urlRuleMeet &&
        coverRuleMeet &&
        repoRuleMeet
      )
    ) {
      // if app's info incorrect, disable submit button.
      setMsg({ show: true, type: 1, content: "请填入正确内容" });
      return;
    }

    const { zhName, enName, url, cover, repo } = onChange();

    const res = await request({
      path: "my-apps",
      method: "post",
      param: { zhName, enName, url, cover, repo, open },
    });

    if (res.data?.code === 200) {
      pushAppsInfo(res.data.data);
      setShowQC(false);
    } else {
      setMsg({ type: 1, content: res.data.message, show: true });
    }
  };

  // cancel to create an app.
  const onCancel = () => {
    setShowQC(false);
  };

  return (
    <>
      <Mask />
      {msg.show && (
        <MsgBox
          type={msg.type}
          content={msg.content}
          onClose={() => setMsg({ ...msg, show: false })}
        />
      )}
      <div className={style.wrapper}>
        <div>
          <label>新建项目</label>
        </div>
        <div>
          <label>项目中文名</label>
          <input
            type="text"
            required
            ref={zhNameRef}
            onChange={onChange}
            style={{ border: zhNameRuleMeet ? "" : "3px solid red" }}
          />
        </div>
        <div>
          <label>项目英文名</label>
          <input
            type="text"
            required
            ref={enNameRef}
            onChange={onChange}
            style={{ border: enNameRuleMeet ? "" : "3px solid red" }}
          />
        </div>
        <div>
          <label>默认网址</label>
          <input
            type="text"
            required
            ref={urlRef}
            onChange={onChange}
            style={{ border: urlRuleMeet ? "" : "3px solid red" }}
          />
        </div>
        <div>
          <label>封面图</label>
          <input
            type="text"
            ref={coverRef}
            onChange={onChange}
            style={{ border: coverRuleMeet ? "" : "3px solid red" }}
          />
        </div>
        <div>
          <label>仓库地址</label>
          <input
            type="text"
            ref={repoRef}
            onChange={onChange}
            style={{ border: repoRuleMeet ? "" : "3px solid red" }}
          />
        </div>
        <div className={style.open}>
          <label>公开</label>
          <input type="checkbox" onClick={() => setOpen(!open)} />
        </div>
        <div className={style.btns}>
          <button onClick={onSubmit}>确认</button>
          <button onClick={onCancel}>取消</button>
        </div>
      </div>
    </>
  );
}

export default observer(AppQuickCreate);
