import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { HomeSwiperElement } from "../HomeSwiperElement";
import style from "./index.module.scss";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

export function HomeSwiper() {
  const urls = [
    "https://s.cn.bing.net/th?id=OHR.EmeraldYoho_ZH-CN4524610330_1920x1080.jpg&rf=LaDigue_1920x1080.jpg",
    "https://cn.bing.com/th?id=OHR.BlackpoolBeach_ZH-CN2646268897_1920x1080.jpg&rf=LaDigue_1920x1080.jpg",
    "https://cn.bing.com/th?id=OHR.PianePuma_ZH-CN1482049046_1920x1080.jpg&rf=LaDigue_1920x1080.jpg",
    "https://cn.bing.com/th?id=OHR.Aracari_ZH-CN0383753817_1920x1080.jpg&rf=LaDigue_1920x1080.jpg",
    "https://s.cn.bing.net/th?id=OHR.EmeraldYoho_ZH-CN4524610330_1920x1080.jpg&rf=LaDigue_1920x1080.jpg",
  ];

  return (
    <div className={style.container}>
      <Swiper
        loop={true}
        grabCursor={true}
        autoplay={{ delay: 3000 }}
        slidesPerView={1}
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Autoplay, Navigation, Pagination]}
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
      >
        {urls.map((url, idx) => {
          return (
            <SwiperSlide key={idx}>
              <HomeSwiperElement url={url} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
