import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AppInfoCard } from "../components/my-app-page/AppInfoCard";
import AppQuickCreate from "../components/my-app-page/AppQuickCreate";
import { LoginStore } from "../store/login.store";
import myAppsStore, { MyAppStore } from "../store/my-apps.store";
import style from "./my-app.page.module.less";

interface IProps {
  myAppStore: MyAppStore;
  loginStore: LoginStore;
}

function MyAppPage(props: IProps) {
  const { appId } = useParams();
  const { isLogin } = props.loginStore;

  const {
    getAppsInfoByPage,
    setShowQC,
    setCurPage,
    showQC,
    appsInfo,
    curPage,
    totalPage,
  } = props.myAppStore;
  const limit = 10;

  // click button to previous page
  const onPrePageClk = () => {
    const _curPage = curPage - 1;
    setCurPage(_curPage);
  };

  // click button to next page
  const onNextPageClk = () => {
    const _curPage = curPage + 1;
    setCurPage(_curPage);
    if (_curPage * limit > Math.ceil(appsInfo.length / limit) * 10) {
      getAppsInfoByPage({ page: _curPage, limit });
      console.log(appsInfo.length);
    }
  };

  useEffect(() => {
    getAppsInfoByPage({ page: 1, limit });
  }, [getAppsInfoByPage, isLogin]);

  return (
    <>
      {/* when appId is undefined, it means that the url is like this: ****\/apps/mine */}
      {appId === undefined && (
        <div className={style.container}>
          <div className={style.editor}>
            <button>查找</button>
            <button onClick={() => setShowQC(true)}>新建</button>
            <button>排序</button>
          </div>
          <div className={style.cards}>
            {appsInfo.length > 0 &&
              appsInfo.map((appInfo, idx) => {
                // only render items of the current page
                if (
                  idx + 1 > (curPage - 1) * limit &&
                  idx + 1 <= curPage * limit
                ) {
                  return <AppInfoCard appInfo={appInfo} key={idx} />;
                } else {
                  return null;
                }
              })}
          </div>
          <div className={style.pagination}>
            <button onClick={onPrePageClk} disabled={curPage <= 1}>
              上一页
            </button>
            <button>
              {"页码 " + (totalPage === 0 ? 0 : curPage) + " / " + totalPage}
            </button>
            <button onClick={onNextPageClk} disabled={curPage >= totalPage}>
              下一页
            </button>
          </div>
        </div>
      )}
      {showQC && <AppQuickCreate myAppStore={myAppsStore} />}
      {/* when appId is defined, it means that the url is like this: ****\/apps/mine/1
          then my-app.page.tsx should render the workspace of the app with appId. And the component
          AppWorkbench will replace the <Outlet /> because of the nested route in MainContent component.
      */}
      {appId !== undefined && <Outlet />}
    </>
  );
}

export default observer(MyAppPage);
