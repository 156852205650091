import dayjs from "dayjs";
import { uniqBy } from "lodash";
import { autorun, makeAutoObservable, runInAction } from "mobx";
import { request } from "../api/requst";
import IDeployRec from "../types/deploy.type";

export class DeployStore {
  deployRecords: IDeployRec[] = [];
  curPage = 1;
  curAppId: number = null;
  tableOptions: null;
  showDeployQC: boolean = false;
  refresh: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.getDeployRecByPage = this.getDeployRecByPage.bind(this);
    this.clearDeployRecords = this.clearDeployRecords.bind(this);
    this.setCurPage = this.setCurPage.bind(this);
    this.setCurAppId = this.setCurAppId.bind(this);
    this.checkAppIdChange = this.checkAppIdChange.bind(this);
    this.setTableOptions = this.setTableOptions.bind(this);
    this.setShowDeployQC = this.setShowDeployQC.bind(this);
    this.pushDeployRecord = this.pushDeployRecord.bind(this);
    this.forceRefresh = this.forceRefresh.bind(this);
    autorun(() => {});
  }

  clearDeployRecords() {
    this.deployRecords = [];
  }

  setDeployRecords(records: IDeployRec[]) {
    this.deployRecords = records;
  }

  setCurPage(curPage: number) {
    this.curPage = curPage;
  }

  setCurAppId(curAppId: number) {
    this.curAppId = curAppId;
  }

  // fetch data again when app is changing.
  checkAppIdChange(curAppId: number) {
    if (this.curAppId !== curAppId) {
      this.clearDeployRecords();
    }
    this.setCurAppId(curAppId);
  }

  setTableOptions(tableOptions) {
    this.tableOptions = tableOptions;
  }

  setShowDeployQC(status: boolean) {
    this.showDeployQC = status;
  }

  pushDeployRecord(deployRec: IDeployRec) {
    this.deployRecords.push(deployRec);
  }

  forceRefresh() {
    this.clearDeployRecords();
    this.refresh = !this.refresh;
  }

  /**
   * @description: fetch data of page xxx
   * @param {number} prams.page
   * @param {number} prams.pageSize: number of records in every page
   * @param {string} prams.sort: sort by xxx
   * @param {number} prams.appId: current appId
   * @return {IDeployRec[]} data of current page
   */
  async getDeployRecByPage(params: {
    page?: number;
    pageSize?: number;
    sort?: string;
    appId: number;
  }) {
    const { page, pageSize, sort, appId } = params;
    this.checkAppIdChange(+appId);

    const res = await request({
      path: `app/${appId}/deploys`,
      method: "get",
      param: { _page: page || 1, _limit: pageSize || 10, _sort: sort || "id" },
    });

    if (res.data?.code === 200) {
      runInAction(() => {
        this.deployRecords.push(...res.data.data);
        // avoid repeated data
        this.setDeployRecords(uniqBy(this.deployRecords, "id"));
        // pre-process : time
        this.deployRecords = this.deployRecords.map((deploy) => {
          const { createdAt, validatedAt, modifiedAt } = deploy;
          const format = "YYYY-MM-DD HH:mm:ss";
          return {
            ...deploy,
            createdAt: dayjs(createdAt).format(format),
            validatedAt: validatedAt && dayjs(validatedAt).format(format),
            modifiedAt: modifiedAt && dayjs(modifiedAt).format(format),
          };
        });
      });
      return res.data.data;
    }
  }
}

export default new DeployStore();
