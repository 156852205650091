import React from "react";
import { LoginStore } from "../../../store/login.store";
import style from "./index.module.less";

interface IProps {
  loginStore: LoginStore;
}

export function LoginAction(props: IProps) {
  const grayStyle = { backgroundColor: "gray" };
  const { isLogin, loginOut } = props.loginStore;

  const onLoginClk = () => {
    window.location.href = "/login";
  };

  const onSignClk = () => {
    window.location.href = "/sign-up";
  };

  return (
    <div className={style.container}>
      <button
        onClick={isLogin ? null : onLoginClk}
        style={isLogin ? grayStyle : {}}
      >
        登录
      </button>
      <button
        onClick={isLogin ? null : onSignClk}
        style={isLogin ? grayStyle : {}}
      >
        注册
      </button>
      <button
        onClick={!isLogin ? null : loginOut}
        style={!isLogin ? grayStyle : {}}
      >
        登出
      </button>
    </div>
  );
}
