import { autorun, makeAutoObservable, runInAction } from "mobx";
import { request } from "../api/requst";
import { IAppInfo } from "../types/my-app.type";
import { remove, uniqBy } from "lodash";

export class MyAppStore {
  appsInfo: IAppInfo[] = [];
  curPage: number = 0;
  totalPage: number = 0;
  showQC: boolean = false; // QC means quick create

  constructor() {
    makeAutoObservable(this);
    this.getAppsInfoByPage = this.getAppsInfoByPage.bind(this);
    this.setShowQC = this.setShowQC.bind(this);
    this.pushAppsInfo = this.pushAppsInfo.bind(this);
    this.setCurPage = this.setCurPage.bind(this);
    this.setTotalPage = this.setTotalPage.bind(this);
    this.delAppInfo = this.delAppInfo.bind(this);

    autorun(() => {});
  }

  setAppsInfo(appsInfo: IAppInfo[]) {
    this.appsInfo = appsInfo;
  }

  pushAppsInfo(appsInfo: IAppInfo) {
    this.appsInfo.push(appsInfo);
  }

  setShowQC(status: boolean) {
    this.showQC = status;
  }

  setCurPage(curPage: number) {
    this.curPage = curPage;
  }

  setTotalPage(pages: number) {
    this.totalPage = pages;
  }

  delAppInfo(appId: number) {
    remove(this.appsInfo, (appInfo) => appInfo.id === appId);
  }

  /**
   * @description: fetch data of page xxx
   * @param {number} prams.page
   * @param {number} prams.limit: number of cards in every page
   * @param {string} prams.sort: sort by xxx
   * @return {IAppInfo[]} data of current page
   */
  async getAppsInfoByPage(params: {
    page: number;
    limit: number;
    sort?: "id";
  }) {
    const { page, limit, sort } = params;
    const res = await request({
      path: "my-apps",
      method: "get",
      param: { _page: page, _limit: limit, _sort: sort },
    });

    if (res.data?.code === 200) {
      console.log(res.data);
      runInAction(() => {
        const _appsInfo = this.appsInfo;
        _appsInfo.push(...res.data.data.appsInfo);
        this.setAppsInfo(uniqBy(_appsInfo, "id")); // avoid repeated data.
      });
      this.setCurPage(page);
      this.setTotalPage(Math.ceil(res.data.data.total / limit));
      return res.data.data.appsInfo;
    }
  }
}

export default new MyAppStore();
