import React, { useState } from "react";
import { IAppInfo } from "../../../types/my-app.type";
import style from "./index.module.less";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { Link } from "react-router-dom";
import deleteIcon from "../../../static/icons/delete-icon.svg";
import { request } from "../../../api/requst";
import MsgBox from "../../msg-popup-box";

interface IProps {
  appInfo: IAppInfo;
}

export function AppInfoCard(props: IProps) {
  const { cover, zhName, url, id } = props.appInfo;
  const [msgBox, setMsgBox] = useState<{
    type?: number;
    content?: string;
    onSure?: Function;
    show: boolean;
  }>({ show: false });

  const toPage = () => {
    window.open(url);
  };

  const onAppDelete = () => {
    request({ path: "my-apps/" + id, method: "delete" })
      .then((res) => {
        if (res.data.code === 200) {
          setMsgBox({ show: true, content: "删除成功", type: 3 });
        } else {
          setMsgBox({
            show: true,
            content: "删除失败: " + res.data.message,
            type: 1,
          });
        }
      })
      .catch((err) => {
        setMsgBox({
          show: true,
          content: "删除失败: " + err,
          type: 1,
        });
      });
  };

  return (
    <>
      {msgBox.show && (
        <MsgBox
          type={msgBox.type}
          content={msgBox.content}
          onSure={msgBox.onSure}
          onClose={() => setMsgBox({ show: false })}
        />
      )}
      <div className={style.container}>
        <Tooltip
          placement="right"
          // overlay's height is 34px by default.
          overlay={
            <span style={{ lineHeight: "34px" }} onClick={toPage}>
              {url}
            </span>
          }
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
        >
          <Link to={id + ""}>
            <img src={cover} alt="" className={style.cover} />
          </Link>
        </Tooltip>
        <div className={style.zhName}>
          <span>{zhName}</span>
          <img
            src={deleteIcon}
            alt="删除"
            onClick={() => {
              setMsgBox({
                show: true,
                type: 2,
                content: "确定删除项目 " + zhName + " ?",
                onSure: onAppDelete,
              });
            }}
          />
        </div>
      </div>
    </>
  );
}
