import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { UserInfo } from "../components/user-info/UserInfo";
import { stopBubble } from "../tools/stopBubble";
import style from "./index.module.less";
import { observer } from "mobx-react";
import { LoginStore } from "../store/login.store";

function Header({ loginStore }: { loginStore: LoginStore }) {
  const [showUserInfo, setShowUserInfo] = useState(false);

  const menus = [
    { path: "/home", title: "首页" },
    { path: "/apps/mine", title: "我的应用" },
    { path: "/designer", title: "设计" },
    { path: "/apps/all", title: "所有应用" },
    { path: "/admin", title: "管理员" },
  ];

  // event for avatar's click.
  const onAvatarClk = (e) => {
    setShowUserInfo(!showUserInfo);
    stopBubble(e);
  };

  // if user click anywhere in the page, then the UserInfo pop-up is hidden.
  const onPageClk = () => {
    setShowUserInfo(false);
  };

  useEffect(() => {
    document.addEventListener("click", onPageClk);
  }, []);

  return (
    <div className={style.header}>
      <div className={style.menu}>
        {menus.map((menu, idx) => {
          return (
            <li key={idx}>
              <NavLink
                to={menu.path}
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                {menu.title}
              </NavLink>
            </li>
          );
        })}
      </div>
      <div className={style["avatar"]} onClick={onAvatarClk}>
        {loginStore.isLogin ? (
          <img src={loginStore.user["avatar"]} alt="头像" />
        ) : (
          <div className={style["not-login-text"]}>未登录</div>
        )}
      </div>
      {showUserInfo && <UserInfo loginStore={loginStore} />}
    </div>
  );
}

export default observer(Header);
