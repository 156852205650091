import React, { useEffect, useRef, useState } from "react";
import style from "./login.page.module.less";
import eyeClose from "../static/icons/eye-slash-regular.svg";
import eyeOpen from "../static/icons/eye-regular.svg";
import { observer } from "mobx-react";
import { LoginStore } from "../store/login.store";
import MsgBox from "../components/msg-popup-box";

interface IProps {
  loginStore: LoginStore;
}

function LoginPage(props: IProps) {
  const usernameRef = useRef(null);
  const pwdRef = useRef(null);
  const pwdConfirmRef = useRef(null); // confirm the password again
  const pathname = window.location.pathname;
  const { loginStore } = props;

  const [pwdVisible, setPwdVisible] = useState(false);
  const [username, setUsername] = useState(null);
  const [pwd, setPwd] = useState(null);
  const [pwdConfirm, setPwdConfirm] = useState(null);
  const [remember, setRemember] = useState(false); // remember the password
  const [isSignUp, setIsSignUp] = useState(pathname !== "/login"); // is sign-up mode?
  const [nameRuleMeet, setNameRuleMeet] = useState(false);
  const [pwdRuleMeet, setPwdRuleMeet] = useState(false);

  const [msg, setMsg] = useState<{
    show: boolean;
    type?: number;
    content?: string;
    onclose?: Function;
    onsure?: Function;
  }>({ show: false });

  // enter username
  const onUsernameEnter = () => {
    const _username: string = usernameRef.current.value;
    if (_username.match(/^[a-zA-Z][-_a-zA-Z0-9]{3,40}/)) {
      setUsername(_username);
      setNameRuleMeet(true);
    } else {
      setUsername(null);
      setNameRuleMeet(false);
    }
    console.log(_username);
  };

  // enter password
  const onPwdEnter = () => {
    const _pwd = pwdRef.current.value;
    if (_pwd.match(/[-_a-zA-Z0-9]{3,40}/)) {
      setPwdRuleMeet(true);
      setPwd(_pwd);
    } else {
      setPwd(null);
      setPwdRuleMeet(false);
    }
    console.log(_pwd);
  };

  // confirm password
  const onPwdConfirmEnter = () => {
    if (pwdConfirmRef.current.value === pwd) {
      setPwdConfirm(true);
    } else {
      setPwdConfirm(false);
    }
  };

  // login submit and sign up submit
  const onSubmit = async () => {
    if (isSignUp) {
      const [status, message] = await loginStore.createUser({ username, pwd });
      if (status === false) {
        setMsg({ show: true, content: "创建用户失败：" + message, type: 2 });
      } else {
        setMsg({ show: true, content: "创建用户成功：" + message, type: 2 });
      }
    } else {
      const [status, message] = await loginStore.loginByUserInfo({
        username,
        pwd,
        remember,
      });
      if (status) {
        if (window.history.length > 1) {
          window.history.back();
        } else {
          window.location.href = "/home";
        }
      } else {
        setMsg({ show: true, content: "登录失败，请重试:" + message, type: 2 });
      }
    }
  };

  useEffect(() => {
    if (loginStore.isLogin) {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.location.href = "/home";
      }
    }
  });

  return (
    <>
      {msg.show && (
        <MsgBox
          type={msg.type}
          content={msg.content}
          onClose={msg.onclose ? msg.onclose : () => setMsg({ show: false })}
          onSure={msg.onsure ? msg.onsure : null}
        />
      )}
      <div className={style.wrapper}>
        <div className={style.container}>
          <div className={style.left}>其他登录方式暂不可用</div>
          <div className={style.right}>
            <div
              className={style["switch"]}
              onClick={() => setIsSignUp(!isSignUp)}
            >
              {isSignUp ? "去登录" : "去注册"}
            </div>
            <div className={style.username}>
              <label>用户名</label>
              <input
                type="text"
                ref={usernameRef}
                onChange={onUsernameEnter}
                style={{ border: nameRuleMeet ? "none" : "2px solid red" }}
              />
            </div>
            <div className={style.password}>
              <label>密码</label>
              <input
                type={pwdVisible ? "text" : "password"}
                ref={pwdRef}
                onChange={onPwdEnter}
                style={
                  isSignUp
                    ? {
                        border:
                          pwdConfirm && pwdRuleMeet ? "none" : "2px solid red",
                      }
                    : {
                        border: pwdRuleMeet ? "none" : "2px solid red",
                      }
                }
              />
              <img
                src={pwdVisible ? eyeOpen : eyeClose}
                onClick={() => setPwdVisible(!pwdVisible)}
                alt=""
              />
            </div>
            <>
              {isSignUp && (
                <div className={style.password}>
                  <label style={{ fontSize: "1.2rem" }}>确认密码</label>
                  <input
                    type={pwdVisible ? "text" : "password"}
                    ref={pwdConfirmRef}
                    onChange={onPwdConfirmEnter}
                    style={{
                      border:
                        pwdConfirm && pwdRuleMeet ? "none" : "2px solid red",
                    }}
                  />
                </div>
              )}
            </>
            <div className={style.submit}>
              {isSignUp ? (
                <>
                  <div style={{ width: "10rem" }} />
                  <button
                    onClick={!pwdConfirm ? null : onSubmit}
                    style={{ backgroundColor: pwdConfirm ? "" : "gray" }}
                  >
                    注册
                  </button>
                </>
              ) : (
                <>
                  <div className={style.remember}>
                    <label>记住密码</label>
                    <input
                      type="checkbox"
                      onChange={() => setRemember(!remember)}
                    />
                  </div>
                  <button
                    onClick={nameRuleMeet && pwdRuleMeet ? onSubmit : null}
                    style={{
                      backgroundColor:
                        nameRuleMeet && pwdRuleMeet ? "" : "gray",
                    }}
                  >
                    登录
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(LoginPage);
