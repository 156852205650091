import React from "react";
import { LoginStore } from "../../../store/login.store";
import { stopBubble } from "../../../tools/stopBubble";
import { BaseInfo } from "../BaseInfo";
import { LoginAction } from "../LoginAction";
import { Personlization } from "../Personlization";
import style from "./index.module.less";

interface IProps {
  loginStore: LoginStore;
}

// user here is passed from login.store
export function UserInfo(props: IProps) {
  const { user } = props.loginStore;

  return (
    <div className={style.container} onClick={stopBubble}>
      <BaseInfo user={user} />
      <Personlization user={user} />
      <LoginAction loginStore={props.loginStore} />
    </div>
  );
}
