import React from "react";
import style from "./index.module.less";

interface IProps {
  user: {
    avatar: string;
    nick: string;
    role: string;
  };
}

export function BaseInfo(props: IProps) {
  const user = props.user || {};

  return (
    <div className={style.container}>
      <div className={style.header}>基础信息</div>
      <div className={style.nick}>
        <label>昵称</label>
        <div>{user["userName"]}</div>
      </div>
      <div className={style.status}>
        <label>状态</label>
        <div>{user["status"]}</div>
      </div>
      <div className={style.role}>
        <label>角色</label>
        <div>{user["userRole"] === 0 ? "管理员" : "一般用户"}</div>
      </div>
    </div>
  );
}
