import { autorun, makeAutoObservable } from "mobx";
import { request } from "../api/requst";
import { IUserInfo } from "../types/user.type";

export class LoginStore {
  isLogin: boolean = false;
  user: IUserInfo = null;

  constructor() {
    makeAutoObservable(this);
    this.setLoginStatus = this.setLoginStatus.bind(this);
    this.loginBySession = this.loginBySession.bind(this);
    this.loginOut = this.loginOut.bind(this);
    this.loginByUserInfo = this.loginByUserInfo.bind(this);
    this.setUser = this.setUser.bind(this);

    this.loginBySession();
    autorun(() => {
      console.log("from store: login status", this.isLogin, this.getUser);
    });
  }

  setLoginStatus(newLoginStatus: boolean) {
    this.isLogin = newLoginStatus;
    this.isLogin || this.setUser(null);
  }

  setUser(user: any) {
    this.user = user;
  }

  get getUser() {
    return {
      ...this.user,
    };
  }

  get getLoginStatus() {
    return this.isLogin;
  }

  /**
   * @description: login by session
   * @return {array} [boolean, message]
   */
  async loginBySession() {
    const res = await request({ path: "login", method: "post" });
    console.log(res);
    if (res.data.code === 200) {
      this.setUser(res.data.data);
      this.setLoginStatus(true);
      return true;
    } else {
      this.setLoginStatus(false);
      return false;
    }
  }

  /**
   * @description: login by user information
   * @param {string} username
   * @param {string} password
   * @param {string} remember
   * @return {array} [boolean, message]
   */
  async loginByUserInfo(params: {
    username: string;
    pwd: string;
    remember: boolean;
  }) {
    const { username, pwd, remember } = params;
    const res = await request({
      path: "login",
      method: "post",
      param: { remember },
      auth: { username, password: pwd },
    });
    console.log(res);
    if (res.data.code === 200) {
      this.setUser(res.data.data);
      this.setLoginStatus(true);
      return [true, JSON.stringify(res.data.data)];
    } else {
      this.setLoginStatus(false);
      return [false, res.data.message];
    }
  }

  /**
   * @description: create a new user
   * @param {string} params.username
   * @param {string} params.password
   * @return {boolean} success: true, failed: false
   */
  async createUser(params: { username: string; pwd: string }) {
    const { username, pwd } = params;
    const res = await request({
      path: "sign-up",
      method: "post",
      auth: { username, password: pwd },
    });
    console.log(res);
    return [
      res.data.code === 200,
      JSON.stringify(res.data.data) || res.data.message,
    ];
  }

  /**
   * @description: logout
   * @return {boolean} true: logout success. false: logout failed.
   */
  async loginOut(): Promise<boolean> {
    const res = await request({ path: "login-out", method: "post" });
    if (res.data.code === 200) {
      this.setLoginStatus(false);
      this.setUser(null);
      return true;
    } else {
      return false;
    }
  }
}

export default new LoginStore();
