import React from "react";
import style from "./index.module.less";

interface IProps {
  user: {
    avatar: string;
    nick: string;
    role: string;
  };
}

export function Personlization(props: IProps) {
  // const user = props.user || {};

  return (
    <div className={style.container}>
      <div className={style.header}>设置中心</div>
      <div className={style.btns}>
        <button>修改昵称</button>
        <button>更换主题</button>
        <button>更换头像</button>
        <button>切换语言</button>
      </div>
    </div>
  );
}
