import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { request } from "../../../../../../api/requst";
import { DeployStore } from "../../../../../../store/deploy.store";
import { Mask } from "../../../../../Mask";
import style from "./index.module.less";

interface IProps {
  deployStore: DeployStore;
}

// QC means quick create
function DeployQC(props: IProps) {
  const { setShowDeployQC, pushDeployRecord, curAppId } = props.deployStore;
  const [selectedFile, setSelectedFile] = useState<any>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const textRef = useRef();

  const changeHandler = (event: any) => {
    if (event.target.files[0] !== undefined) {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setSelectedFile(null);
      setIsFilePicked(false);
    }
  };

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    const res = await request({
      path: `file/upload/${curAppId}`,
      method: "post",
      param: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (res.data.code === 200) {
      setShowDeployQC(false);
    }
  };

  const onCancel = () => {
    setShowDeployQC(false);
  };

  return (
    <>
      <Mask></Mask>
      <div className={style.wrapper}>
        <div className={style["file-uploader"]}>
          <label>选择文件</label>
          <div className={style["file-info"]}>
            <input
              type="file"
              id="deploy-qc-file-browser"
              style={{ display: "none" }}
              onChange={changeHandler}
            />
            <input
              className={style["file-upload-btn"]}
              type="button"
              value="浏览..."
              onClick={() =>
                document.getElementById("deploy-qc-file-browser").click()
              }
            />
            {isFilePicked ? (
              <div>
                <p>
                  <span>filename:</span> {selectedFile.name}
                </p>
                <p>
                  <span>filetype:</span> {selectedFile.type}
                </p>
                <p>
                  <span>size in bytes:</span> {selectedFile.size}
                </p>
                <p>
                  <span>last modified date:</span>{" "}
                  {selectedFile.lastModifiedDate.toLocaleDateString()}
                </p>
              </div>
            ) : (
              <div>
                <p>Select a file to show details</p>
              </div>
            )}
          </div>
        </div>
        <div className={style["deploy-comment"]}>
          <label>发布信息</label>
          <textarea ref={textRef} />
        </div>
        <div className={style.btns}>
          <button
            onClick={onSubmit}
            disabled={!isFilePicked}
            style={{ cursor: isFilePicked ? "pointer" : "not-allowed" }}
          >
            发布
          </button>
          <button onClick={onCancel}>取消</button>
        </div>
      </div>
    </>
  );
}

export default observer(DeployQC);
